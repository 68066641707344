// var config = {
//     apiKey: "AIzaSyBkzuJGa7ouYuzpR2RbV2viDbkJWKQK4ug",
//     authDomain: "eiria-project.firebaseapp.com",
//     projectId: "eiria-project",
//     storageBucket: "eiria-project.appspot.com",
//     messagingSenderId: "300549676065",
//     appId: "1:300549676065:web:b276be864a15dfc513d760"
// };

// firebase.initializeApp(config);

// const messaging = firebase.messaging();


// function initFirebaseMessagingRegistration() {
//     messaging.requestPermission().then(function () {
//         return messaging.getToken()
//     }).then(function (token) {
//         axios.post("fcm-token", {
//             _method: "PATCH",
//             device_token: token,
//             device_id: 'Master/Owner',
//             device_name: 'web',
//             device_type: 'browser',
//         }).then(({ data }) => {
//             console.log(data)
//         }).catch(({ response: { data } }) => {
//             console.error(data)
//         })
//     }).catch(function (err) {
//         console.log(`Token Error :: ${err}`);
//     });

// }

// initFirebaseMessagingRegistration();

$(".sidebar-toggle").on("click", function () {
    $(".sidebar")
        .toggleClass("collapsed")
        .one("transitionend", function () {
            setTimeout(function () {
                window.dispatchEvent(new Event("resize"));
            }, 100);
        });
});


function date(date) {
    const d = new Date(date);
    const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
    const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
    const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
    // console.log(`${da}-${mo}-${ye}`);
    return `${da}-${mo}-${ye}`;
}

function setFile(cls) {
    var fileName = $('.' + cls).val();
    //replace the "Choose a file" label
    $('.' + cls).next('.custom-file-label').html(fileName);
};

$(document).on('change', '.custom-file-input', function () {
    setFile('custom-file-input');
});

function show(type) {
    if (type == 'banner') {
        $("#allBanner").html('<i class="fas fa-spinner"></i>');
        $("#btn-sbm").html('Submit');
        $('#banner_id').val('');
        $('#heading').val('');
        $('#short_description').val('');
        $('#image').val('');
        setFile('custom-file-input');
        $('.custom-control-input').prop('checked', false);
        $('input[value="active"]').prop('checked', true);
        $(".alert_msg").html('');

        axios.get('showAll')
            .then(res => {
                let banner = '<div class="row">';
                $.each(res.data.bannners, function (key, value) {
                    let act = '';
                    let inct = '';
                    if (value.status == 'active') {
                        act = 'checked';
                    }
                    else {
                        inct = 'checked';
                    }

                    banner += '<div class="col-sm-4">';
                    banner += '<img src="' + res.data.path + '/' + value.image + '"  class="img-thumbnail"  width="304" height="236">';
                    banner += '<div class="row">';
                    banner += '<div class="col-sm-6">';
                    banner += '<div class="custom-control custom-radio">';
                    banner += '<input name="status' + value.id + '" value="active" class="custom-control-input banner-' + value.id + '" id="ban-ac-' + value.id + '" type="radio" ' + act + '>';
                    banner += '<label for="ban-ac-' + value.id + '" class="custom-control-label">Active</label>';
                    banner += '</div>';
                    banner += '<div class="custom-control custom-radio">';
                    banner += '<input name="status' + value.id + '" value="inactive" class="custom-control-input banner-' + value.id + '" type="radio" id="ban-in-' + value.id + '" ' + inct + '>';
                    banner += '<label for="ban-in-' + value.id + '" class="custom-control-label">Inactive</label>';
                    banner += '</div>';
                    banner += '</div>';
                    banner += '<div class="col-sm-3">';
                    banner += '<button type="button" class="btn btn-info edit-ban" value="' + value.id + '">Edit</button>';
                    banner += '</div>';
                    banner += '<div class="col-sm-3">';
                    banner += '<button type="button" class="btn btn-danger del-ban" value="' + value.id + '">Delete</button>';
                    banner += '</div>';
                    banner += '</div>';
                    banner += '</div>';
                });
                banner += '</div>';

                $("#allBanner").html(banner);

            })
            .catch(err => {
                $("#allBanner").html('<div class="alert alert-info  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"> ' +
                    err.response.data.msg +
                    "</div>");
            });
    } else if (type == 'branch') {
        $("#branch-table").children('tbody').html('<tr><td colspan="6"><i class="fas fa-spinner"></i></td></tr>');
        axios.get('showBranch')
            .then(res => {

                let view = '';
                $.each(res.data.branches, function (key, branch) {
                    view += '<tr>';
                    view += '<td>';
                    if (branch.image != null) {
                        view += '<img width="50" height="50" src="' + res.data.path + '/' + branch.image + '" />';
                        branch.image;
                    } else {
                        view += '<img width="50" height="50" src="' + $('#url').val() + '/default.png" />';

                    }
                    view += '</td>';

                    view += '<td>' + branch.name + '</td>';
                    view += '<td>' + branch.address + '</td>';
                    view += '<td>' + branch.status + '</td>';
                    view += '<td>';
                    view += '<button type="button" class="btn btn-primary btn-sm branch_def" value="' + branch.id + '"';
                    if (branch.is_default == 'yes') {
                        view += ' disabled ';
                    }
                    view += '>';
                    if (branch.is_default == 'yes') {
                        view += 'Active';
                    } else {
                        view += 'Set Default';
                    }
                    view += '</button>';

                    view += '</td>';
                    view += '<td>';

                    view += ' <button type="submit" class="btn btn-primary btn-sm edit-branch" value="' + branch.id + '">Edit</button>';
                    view += ' <button type="submit" class="btn btn-danger btn-sm del-branch" value="' + branch.id + '">Delete</button>';

                    view += '</td>';

                    view += '</tr>';

                });

                $('#branch-table').children('tbody').html(view);
                $('#branch-table').DataTable();
            })
            .catch(err => {
                $('#branch-table').children('tbody').html('<tr><td colspan="6"><div class="alert alert-info  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"> ' +
                    err.response.data.msg +
                    "</div></td></tr>");
            });
    } else if (type == 'category') {
        $("#category-table").children('tbody').html('<tr><td colspan="6"><i class="fas fa-spinner"></i></td></tr>');
        axios.get('showCategory')
            .then(res => {

                let view = '';
                $.each(res.data.categories, function (key, category) {
                    view += '<tr>';
                    view += '<td>';
                    if (category.image != null) {
                        view += '<img width="50" height="50" src="' + res.data.path + '/' + category.image + '" />';
                        category.image;
                    } else {
                        view += '<img width="50" height="50" src="' + $('#url').val() + '/default.png" />';

                    }
                    view += '</td>';

                    view += '<td>' + category.name + '</td>';
                    view += '<td>' + category.status + '</td>';

                    view += '<td>';

                    view += ' <button type="submit" class="btn btn-primary btn-sm edit-category" value="' + category.id + '">Edit</button>';
                    view += ' <button type="submit" class="btn btn-danger btn-sm del-category" value="' + category.id + '" ' + (category.items.length > 0 ? ("disabled") : "") + '>Delete</button>';

                    view += '</td>';

                    view += '</tr>';

                });

                $('#category-table').children('tbody').html(view);
                $('#category-table').DataTable();
            })
            .catch(err => {
                $('#category-table').children('tbody').html('<tr><td colspan="6"><div class="alert alert-info  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"> ' +
                    err.response.data.msg +
                    "</div></td></tr>");
            });
    } else if (type == 'coupons') {
        $("#coupon-table").children('tbody').html('<tr><td colspan="8"><i class="fas fa-spinner"></i></td></tr>');
        axios.get('showCoupons')
            .then(res => {

                let view = '';
                $.each(res.data.items, function (key, item) {
                    view += '<tr>';

                    view += '<td>' + item.name + '</td>';
                    view += '<td>' + item.description + '</td>';
                    view += '<td>' + item.amount + '</td>';
                    view += '<td>' + item.start_date + '</td>';
                    view += '<td>' + item.end_date + '</td>';
                    view += '<td>' + item.status + '</td>';
                    view += '<td>';
                    view += ' <button type="submit" class="btn btn-primary btn-sm edit-coupon" value="' + item.id + '">Edit</button>';
                    view += ' <button type="submit" class="btn btn-danger btn-sm del-coupon" value="' + item.id + '">Delete</button>';

                    view += '</td>';

                    view += '</tr>';

                });

                $('#coupon-table').children('tbody').html(view);
                $('#coupon-table').DataTable();
            })
            .catch(err => {
                $('#coupon-table').children('tbody').html('<tr><td colspan="8"><div class="alert alert-info  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"> ' +
                    err.response.data.msg +
                    "</div></td></tr>");
            });
    } else if (type == 'delivery') {
        $("#delivery-table").children('tbody').html('<tr><td colspan="3"><i class="fas fa-spinner"></i></td></tr>');
        axios.get('showDelivery')
            .then(res => {

                let view = '';
                $.each(res.data.items, function (key, item) {
                    view += '<tr>';

                    view += '<td>' + item.distance + ' KM</td>';
                    view += '<td>' + item.cost + '</td>';
                    view += '<td class="text-center">';
                    view += ' <button type="submit" class="btn btn-primary btn-sm edit-delivery" value="' + item.id + '">Edit</button>';
                    view += ' <button type="submit" class="btn btn-danger btn-sm del-delivery" value="' + item.id + '">Delete</button>';

                    view += '</td>';

                    view += '</tr>';

                });

                $('#delivery-table').children('tbody').html(view);
                $('#delivery-table').DataTable();
            })
            .catch(err => {
                $('#delivery-table').children('tbody').html('<tr><td colspan="3"><div class="alert alert-info  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"> ' +
                    err.response.data.msg +
                    "</div></td></tr>");
            });
    } else if (type == 'orders') {
        $("#normal-table").children('tbody').html('<tr><td colspan="8"><i class="fas fa-spinner"></i></td></tr>');
        $("#cancel-table").children('tbody').html('<tr><td colspan="9"><i class="fas fa-spinner"></i></td></tr>');
        $("#refund-table").children('tbody').html('<tr><td colspan="7"><i class="fas fa-spinner"></i></td></tr>');
        $("#completed-table").children('tbody').html('<tr><td colspan="7"><i class="fas fa-spinner"></i></td></tr>');
        axios.get('showOrders')
            .then(res => {
                let view = '';
                if (res.data.normal.length != 0) {
                    $.each(res.data.normal, function (key, nrml) {
                        // console.log("customerdefault_address", nrml);
                        view += '<tr>';

                        view += '<td><a class="show-order" id="' + nrml.id + '">' + nrml.id + '</a></td>';
                        view += '<td>' + (nrml.user != null ? nrml.user.name : "") + '</td>';
                        view += '<td>' + (nrml.branch != null ? nrml.branch.name : "") + '</td>';
                        view += '<td>' + nrml.delivery_type + '</td>';
                        view += '<td>' + (nrml.booking != null ? nrml.booking.id : "") + '</td>';
                        view += '<td>' + nrml.status + '</td>';
                        view += '<td>' + nrml.total + '</td>';
                        view += '<td>';
                        view += ' <button type="submit" class="btn btn-primary btn-sm cancel-order" value="' + nrml.id + '">Cancel</button>';

                        view += '</td>';

                        view += '</tr>';

                    });
                    $('#normal-table').children('tbody').html(view);
                    $('#normal-table').DataTable({
                        "order": [],
                        "bDestroy": true
                    });

                } else {
                    $('#normal-table').children('tbody').html('<tr><td colspan="8"><div class="alert alert-info  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"> Data not available</div></td></tr>');
                }

                let wait_view = '';
                if (res.data.cancel.length != 0) {
                    $.each(res.data.cancel, function (key, cncl) {
                        wait_view += '<tr>';

                        wait_view += '<td><a class="show-order" id="' + cncl.id + '">' + cncl.id + '</a></td>';
                        wait_view += '<td>' + (cncl.user != null ? cncl.user.name : "") + '</td>';
                        wait_view += '<td>' + (cncl.branch != null ? cncl.branch.name : "") + '</td>';
                        wait_view += '<td>' + cncl.delivery_type + '</td>';
                        wait_view += '<td>' + (cncl.booking != null ? cncl.booking.id : "") + '</td>';
                        wait_view += '<td>' + cncl.status + '</td>';
                        wait_view += '<td>' + cncl.total + '</td>';
                        wait_view += '<td>' + cncl.payment.transaction_id + '</td>';

                        wait_view += '<td>';

                        // wait_view += ' <button type="submit" class="btn btn-primary btn-sm edit-user" value="' + waiter.id + '">Edit</button>';
                        wait_view += ' <button type="submit" class="btn btn-danger btn-sm refund-order" value="' + cncl.id + '">Refund</button>';

                        wait_view += '</td>';

                        wait_view += '</tr>';

                    });
                    $('#cancel-table').children('tbody').html(wait_view);
                    $('#cancel-table').DataTable({
                        "order": [],
                        "bDestroy": true
                    });
                } else {
                    $('#cancel-table').children('tbody').html('<tr><td colspan="9"><div class="alert alert-info  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"> Data not available</div></td></tr>');
                }

                let kitchen_view = '';
                // console.log(res.data.refund);
                if (res.data.refund.length != 0) {
                    $.each(res.data.refund, function (key, rfnd) {
                        kitchen_view += '<tr>';

                        kitchen_view += '<td> <a class="show-order" id="' + rfnd.id + '">' + rfnd.id + '</a></td>';
                        kitchen_view += '<td>' + (rfnd.user != null ? rfnd.user.name : "") + '</td>';
                        kitchen_view += '<td>' + (rfnd.branch != null ? rfnd.branch.name : "") + '</td>';
                        kitchen_view += '<td>' + rfnd.delivery_type + '</td>';
                        kitchen_view += '<td>' + (rfnd.booking != null ? rfnd.booking.id : "") + '</td>';
                        kitchen_view += '<td>' + rfnd.status + '</td>';
                        kitchen_view += '<td>' + rfnd.total + '</td>';

                        kitchen_view += '</tr>';

                    });
                    $('#refund-table').children('tbody').html(kitchen_view);
                    $('#refund-table').DataTable({
                        "order": [],
                        "bDestroy": true
                    });
                } else {
                    $('#refund-table').children('tbody').html('<tr><td colspan="7"><div class="alert alert-info  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"> Data not available</div></td></tr>');
                }
                let completed_view = '';
                // console.log(res.data.refund);
                if (res.data.completed.length != 0) {
                    $.each(res.data.completed, function (key, cmpl) {
                        completed_view += '<tr>';

                        completed_view += '<td> <a class="show-order" id="' + cmpl.id + '">' + cmpl.id + '</a></td>';
                        completed_view += '<td>' + (cmpl.user != null ? cmpl.user.name : "") + '</td>';
                        completed_view += '<td>' + (cmpl.branch != null ? cmpl.branch.name : "") + '</td>';
                        completed_view += '<td>' + cmpl.delivery_type + '</td>';
                        completed_view += '<td>' + (cmpl.booking != null ? cmpl.booking.id : "") + '</td>';
                        completed_view += '<td>' + cmpl.status + '</td>';
                        completed_view += '<td>' + cmpl.total + '</td>';

                        completed_view += '</tr>';

                    });
                    $('#completed-table').children('tbody').html(completed_view);
                    $('#completed-table').DataTable({
                        "order": [],
                        "bDestroy": true
                    });
                } else {
                    $('#completed-table').children('tbody').html('<tr><td colspan="7"><div class="alert alert-info  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"> Data not available</div></td></tr>');
                }
            })
            .catch(err => {
                // console.log("err",err)
                $('#waiter-table').children('tbody').html('<tr><td colspan="9"><div class="alert alert-info  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"> ' + err.response.data.msg + "</div></td></tr>");
            });
    } else if (type == "tables") {
        //Ramya Roy
        $("#table-table").children('tbody').html('<tr><td colspan="8"><i class="fas fa-spinner"></i></td></tr>');
        axios.get('showTables')
            .then(res => {

                let view = '';
                $.each(res.data.tables, function (key, table) {
                    view += '<tr>';
                    view += '<td>';
                    if (table.branch != null) {
                        view += table.branch.name;
                    } else {
                        view += 'No Branch';

                    }
                    view += '</td>';

                    view += '<td>' + table.number + '</td>';
                    view += '<td>' + table.capacity + '</td>';
                    view += '<td>'
                    if (table.image != null) {
                        view += '<img width="50" height="50" src="' + res.data.path + '/' + table.image + '" />';
                        table.image;
                    } else {
                        view += '<img width="50" height="50" src="' + $('#url').val() + '/default.png" />';

                    }
                    view += '</td>';
                    view += '<td>' + table.status + '</td>';
                    view += '<td>';
                    view += ' <button type="submit" class="btn btn-primary btn-sm edit-tables" value="' + table.id + '">Edit</button>';
                    view += ' <button type="submit" class="btn btn-danger btn-sm del-tables" value="' + table.id + '">Delete</button>';

                    view += '</td>';

                    view += '</tr>';

                });

                $('#table-table').children('tbody').html(view);
                $('table-table').DataTable();
            })
            .catch(err => {
                $('#table-table').children('tbody').html('<tr><td colspan="8"><div class="alert alert-info  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"> ' +
                    err.response.data.msg +
                    "</div></td></tr>");
            });
    } else if (type == "gallery") {
        //Ramya Roy
        $("#gallery-table").children('tbody').html('<tr><td colspan="8"><i class="fas fa-spinner"></i></td></tr>');
        axios.get('showGallery')
            .then(res => {

                let view = '';
                $.each(res.data.items, function (key, item) {
                    view += '<tr>';

                    view += '<td>' + item.alt_name + '</td>';
                    view += '<td>'
                    if (item.image != null) {
                        view += '<img width="50" height="50" src="' + res.data.path + '/' + item.image + '" />';
                        item.image;
                    } else {
                        view += '<img width="50" height="50" src="' + $('#url').val() + '/default.png" />';

                    }
                    view += '</td>';
                    view += '<td>' + item.status + '</td>';
                    view += '<td>';
                    view += ' <button type="submit" class="btn btn-primary btn-sm edit-gallery" value="' + item.id + '">Edit</button>';
                    view += ' <button type="submit" class="btn btn-danger btn-sm del-gallery" value="' + item.id + '">Delete</button>';

                    view += '</td>';

                    view += '</tr>';

                });

                $('#gallery-table').children('tbody').html(view);
                $('gallery-table').DataTable();
            })
            .catch(err => {
                $('#gallery-table').children('tbody').html('<tr><td colspan="8"><div class="alert alert-info  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"> ' +
                    err.response.data.msg +
                    "</div></td></tr>");
            });
    } else if (type == 'items') {
        $("#item-table").children('tbody').html('<tr><td colspan="8"><i class="fas fa-spinner"></i></td></tr>');
        axios.get('showItems')
            .then(res => {

                let view = '';
                $.each(res.data.items, function (key, item) {
                    if (item.total_stock == 0) {
                        view += '<tr class="table-danger">';
                    } else {
                        view += '<tr>';
                    }

                    view += '<td>';
                    if (item.images.length != 0) {
                        item.images.forEach(img => {
                            if (img.is_default == '1') {
                                view += '<img width="50" height="50" src="' + res.data.path + '/' + img.image + '" />';
                            }
                        });
                    } else {
                        view += '<img width="50" height="50" src="' + $('#url').val() + '/default.png" />';

                    }
                    view += '</td>';

                    view += '<td>' + (item.branch != null ? item.branch.name : "") + '</td>';
                    view += '<td>' + (item.category != null ? item.category.name : "") + '</td>';
                    view += '<td>' + item.name + '</td>';
                    view += '<td>' + item.type + '</td>';
                    view += '<td>$' + item.price + (item.offer_price != null ? ' ($' + item.offer_price + ')' : '') + '</td>';
                    view += '<td>' + item.status + '</td>';

                    view += '<td>';

                    view += ' <button type="submit" class="btn btn-primary btn-sm edit-item" value="' + item.id + '">Edit</button>';
                    view += ' <button type="submit" class="btn btn-danger btn-sm del-item" value="' + item.id + '">Delete</button>';

                    view += '</td>';

                    view += '</tr>';

                });


                $('#item-table').children('tbody').html(view);

                $('#item-table').DataTable();

                // if ( $.fn.dataTable.isDataTable( '#item-table' )) {
                //     console.log('working');
                //     table.clear();
                //     console.log('clear');
                //     table.destroy();
                //     console.log('destroy');
                // }else{

                //     table = $('#item-table').DataTable();
                // }


            })
            .catch(err => {
                $('#item-table').children('tbody').html('<tr><td colspan="8"><div class="alert alert-info  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"> ' +
                    err.response.data.msg +
                    "</div></td></tr>");
            });
    } else if (type == 'users') {
        $("#customer-table").children('tbody').html('<tr><td colspan="8"><i class="fas fa-spinner"></i></td></tr>');
        $("#waiter-table").children('tbody').html('<tr><td colspan="9"><i class="fas fa-spinner"></i></td></tr>');
        axios.get('showUsers')
            .then(res => {
                let view = '';
                if (res.data.customers.length != 0) {
                    $.each(res.data.customers, function (key, customer) {
                        // console.log("customerdefault_address", customer.default_address);
                        view += '<tr>';

                        view += '<td>';
                        if (customer.image != null) {
                            if (customer.image.is_default == '1') {
                                view += '<img width="50" height="50" src="' + res.data.path + '/' + customer.image.image + '" />';
                            }
                        } else {
                            view += '<img width="50" height="50" src="' + $('#url').val() + '/default.png" />';

                        }
                        view += '</td>';

                        view += '<td>' + customer.name + '</td>';
                        view += '<td>' + customer.mobile + '</td>';
                        view += '<td>' + date(customer.created_at) + '</td>';
                        view += '<td>'
                        customer.default_address.forEach(adr => {
                            view += adr.address;
                            view += '<br>' + adr.street_no;
                            view += '<br>' + adr.house_flat_no;
                            view += '<br>' + adr.landmark;
                        });
                        view += '</td>';
                        view += '<td>' + customer.status + '</td>';

                        view += '<td>';
                        view += ' <button type="submit" class="btn btn-danger btn-sm del-user" value="' + customer.id + '">Delete</button>';

                        view += '</td>';

                        view += '</tr>';

                    });
                    $('#customer-table').children('tbody').html(view);
                    $('#customer-table').DataTable();

                } else {
                    $('#customer-table').children('tbody').html('<tr><td colspan="8"><div class="alert alert-info  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"> Add your first Customer</div></td></tr>');
                }

                let wait_view = '';
                if (res.data.waiters.length != 0) {
                    $.each(res.data.waiters, function (key, waiter) {
                        wait_view += '<tr>';
                        wait_view += '<td>';
                        if (waiter.branch != null) {
                            // waiter.branch.forEach(branc => {
                            wait_view += waiter.branch.name;
                            // });
                        } else {
                            wait_view += 'No Branch';

                        }
                        wait_view += '</td>';

                        wait_view += '<td>';
                        if (waiter.image != null) {
                            if (waiter.image.is_default == '1') {
                                wait_view += '<img width="50" height="50" src="' + res.data.path + '/' + waiter.image.image + '" />';
                            }
                        } else {
                            wait_view += '<img width="50" height="50" src="' + $('#url').val() + '/default.png" />';

                        }
                        wait_view += '</td>';

                        wait_view += '<td>' + waiter.name + '</td>';
                        wait_view += '<td>' + waiter.email + '</td>';
                        wait_view += '<td>' + waiter.mobile + '</td>';
                        wait_view += '<td>' + date(waiter.created_at) + '</td>';
                        // wait_view += '<td>'
                        // waiter.default_address.forEach(adr => {
                        //     wait_view += adr.address;
                        //     wait_view += '<br>'+adr.nearby;
                        //     wait_view += '<br>'+adr.zipcode;
                        //     wait_view += '<br>'+adr.country;
                        // });
                        // wait_view += '</td>';
                        wait_view += '<td>' + waiter.status + '</td>';

                        wait_view += '<td>';

                        wait_view += ' <button type="submit" class="btn btn-primary btn-sm edit-user" value="' + waiter.id + '">Edit</button>';
                        wait_view += ' <button type="submit" class="btn btn-danger btn-sm del-user" value="' + waiter.id + '">Delete</button>';

                        wait_view += '</td>';

                        wait_view += '</tr>';

                    });
                    $('#waiter-table').children('tbody').html(wait_view);
                    $('#waiter-table').DataTable();
                } else {
                    $('#waiter-table').children('tbody').html('<tr><td colspan="8"><div class="alert alert-info  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"> Add your first Waiter</div></td></tr>');
                }

                let kitchen_view = '';
                if (res.data.kitchens.length != 0) {
                    $.each(res.data.kitchens, function (key, kitchen) {
                        kitchen_view += '<tr>';
                        kitchen_view += '<td>';
                        if (kitchen.branch != null) {
                            // kitchen.branch.forEach(branc => {
                            kitchen_view += kitchen.branch.name;
                            // });
                        } else {
                            kitchen_view += 'No Branch';

                        }
                        kitchen_view += '</td>';

                        kitchen_view += '<td>';
                        if (kitchen.image != null) {
                            if (kitchen.image.is_default == '1') {
                                kitchen_view += '<img width="50" height="50" src="' + res.data.path + '/' + kitchen.image.image + '" />';
                            }
                        } else {
                            kitchen_view += '<img width="50" height="50" src="' + $('#url').val() + '/default.png" />';

                        }
                        kitchen_view += '</td>';

                        kitchen_view += '<td>' + kitchen.name + '</td>';
                        kitchen_view += '<td>' + kitchen.email + '</td>';
                        kitchen_view += '<td>' + kitchen.mobile + '</td>';
                        kitchen_view += '<td>' + date(kitchen.created_at) + '</td>';
                        // kitchen_view += '<td>'
                        // kitchen.default_address.forEach(adr => {
                        //     kitchen_view += adr.address;
                        //     kitchen_view += '<br>'+adr.nearby;
                        //     kitchen_view += '<br>'+adr.zipcode;
                        //     kitchen_view += '<br>'+adr.country;
                        // });
                        // kitchen_view += '</td>';
                        kitchen_view += '<td>' + kitchen.status + '</td>';

                        kitchen_view += '<td>';

                        kitchen_view += ' <button type="submit" class="btn btn-primary btn-sm edit-user" value="' + kitchen.id + '">Edit</button>';
                        kitchen_view += ' <button type="submit" class="btn btn-danger btn-sm del-user" value="' + kitchen.id + '">Delete</button>';

                        kitchen_view += '</td>';

                        kitchen_view += '</tr>';

                    });
                    $('#kitchen-table').children('tbody').html(kitchen_view);
                    $('#kitchen-table').DataTable();
                } else {
                    $('#kitchen-table').children('tbody').html('<tr><td colspan="8"><div class="alert alert-info  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"> Add your first Chef</div></td></tr>');
                }
                let delivery_view = '';
                if (res.data.delivery_boys.length != 0) {
                    $.each(res.data.delivery_boys, function (key, delivery_boy) {
                        delivery_view += '<tr>';
                        delivery_view += '<td>';
                        if (delivery_boy.branch != null) {
                            // kitchen.branch.forEach(branc => {
                            delivery_view += delivery_boy.branch.name;
                            // });
                        } else {
                            delivery_view += 'No Branch';

                        }
                        delivery_view += '</td>';

                        delivery_view += '<td>';
                        if (delivery_view.image != null) {
                            if (delivery_view.image.is_default == '1') {
                                delivery_view += '<img width="50" height="50" src="' + res.data.path + '/' + delivery_boy.image.image + '" />';
                            }
                        } else {
                            delivery_view += '<img width="50" height="50" src="' + $('#url').val() + '/default.png" />';

                        }
                        delivery_view += '</td>';

                        delivery_view += '<td>' + delivery_boy.name + '</td>';
                        delivery_view += '<td>' + delivery_boy.email + '</td>';
                        delivery_view += '<td>' + delivery_boy.mobile + '</td>';
                        delivery_view += '<td>' + date(delivery_boy.created_at) + '</td>';
                        // delivery_boy += '<td>'
                        // kitchen.default_address.forEach(adr => {
                        //     delivery_boy += adr.address;
                        //     delivery_boy += '<br>'+adr.nearby;
                        //     delivery_boy += '<br>'+adr.zipcode;
                        //     delivery_boy += '<br>'+adr.country;
                        // });
                        // delivery_boy += '</td>';
                        delivery_view += '<td>' + delivery_boy.status + '</td>';

                        delivery_view += '<td>';

                        delivery_view += ' <button type="submit" class="btn btn-primary btn-sm edit-user" value="' + delivery_boy.id + '">Edit</button>';
                        delivery_view += ' <button type="submit" class="btn btn-danger btn-sm del-user" value="' + delivery_boy.id + '">Delete</button>';

                        delivery_view += '</td>';

                        delivery_view += '</tr>';

                    });
                    $('#delivery_boy-table').children('tbody').html(delivery_view);
                    $('#delivery_boy-table').DataTable();
                } else {
                    $('#delivery_boy-table').children('tbody').html('<tr><td colspan="8"><div class="alert alert-info  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"> Add your first Chef</div></td></tr>');
                }

            })
            .catch(err => {
                $('#waiter-table').children('tbody').html('<tr><td colspan="8"><div class="alert alert-info  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"> ' +
                    err.response.data.msg +
                    "</div></td></tr>");
            });
    } else if (type == 'restaurants') {
        $("#restaurant-table").children('tbody').html('<tr><td colspan="6"><i class="fas fa-spinner"></i></td></tr>');
        axios.get('showRestaurant')
            .then(res => {

                let view = '';
                $.each(res.data.restaurants, function (key, restaurant) {
                    if (restaurant.favourite == '1') {
                        view += '<tr class="table-success">';
                    } else {
                        view += '<tr>';
                    }

                    view += '<td>'
                    if (restaurant.images.length != 0) {
                        restaurant.images.forEach(img => {
                            if (img.is_default == '1') {
                                view += '<img width="50" height="50" src="' + res.data.path + '/' + img.image + '" />';
                            }
                        });
                    } else {
                        view += '<img width="50" height="50" src="' + $('#url').val() + '/default.png" />';

                    }
                    view += '</td>';
                    view += '<td>';
                    view += restaurant.name;
                    if (restaurant.brand == '1') {
                        view += ' (Branded)';
                    }
                    view += '</td>';
                    view += '<td>' + restaurant.latitude + ' / ' + restaurant.longitude + '</td>';
                    view += '<td>' + restaurant.status + '</td>';
                    view += '<td>';
                    view += ' <button type="submit" class="btn btn-primary btn-sm edit-restaurant" value="' + restaurant.id + '">Edit</button>';
                    view += ' <button type="submit" class="btn btn-danger btn-sm del-restaurant" value="' + restaurant.id + '">Delete</button>';

                    view += '</td>';

                    view += '</tr>';

                });

                $('#restaurant-table').children('tbody').html(view);
                $('#restaurant-table').DataTable();
            })
            .catch(err => {
                $('#restaurant-table').children('tbody').html('<tr><td colspan="6"><div class="alert alert-info  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"> ' +
                    err.response.data.msg +
                    "</div></td></tr>");
            });
    }

}

function map(lat, lng) {
    // var lat = 44.88623409320778, lng = -87.86480712897173;
    let latlng = new google.maps.LatLng(lat, lng);
    console.log(latlng);

    let image = 'http://www.google.com/intl/en_us/mapfiles/ms/micons/blue-dot.png';

    //  zoomControl: true,
    //  zoomControlOptions: google.maps.ZoomControlStyle.LARGE,

    var mapOptions = {
        center: new google.maps.LatLng(lat, lng),
        zoom: 13,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        panControl: true,
        panControlOptions: {
            position: google.maps.ControlPosition.TOP_RIGHT
        },
        zoomControl: true,
        zoomControlOptions: {
            style: google.maps.ZoomControlStyle.LARGE,
            position: google.maps.ControlPosition.TOP_left
        }
    },
        map = new google.maps.Map(document.getElementById('map_canvas'), mapOptions),
        marker = new google.maps.Marker({
            position: latlng,
            map: map,
            icon: image
        });

    var input = document.getElementById('address');
    var autocomplete = new google.maps.places.Autocomplete(input, {
        types: ["geocode"]
    });

    autocomplete.bindTo('bounds', map);
    var infowindow = new google.maps.InfoWindow();

    $('#latitude').val(lat);
    $('#longitude').val(lng);

    // Reassign lat long value another veriable
    let latlong = new google.maps.LatLng(lat, lng);
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({
        "latLng": latlong
    }, function (results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
            var lat = results[0].geometry.location.lat(),
                lng = results[0].geometry.location.lng(),
                placeName = results[0].address_components[0].long_name,
                latlng = new google.maps.LatLng(lat, lng);

            moveMarker(placeName, latlng);
            $("#address").val(results[0].formatted_address);
        }
    });

    google.maps.event.addListener(autocomplete, 'place_changed', function (event) {
        console.log(event)
        infowindow.close();
        var place = autocomplete.getPlace();
        if (place.geometry.viewport) {
            map.fitBounds(place.geometry.viewport);
        } else {
            map.setCenter(place.geometry.location);
            map.setZoom(17);
        }

        moveMarker(place.name, place.geometry.location);
        $('#latitude').val(place.geometry.location.lat());
        $('#longitude').val(place.geometry.location.lng());
    });
    google.maps.event.addListener(map, 'click', function (event) {
        $('#latitude').val(event.latLng.lat());
        $('#longitude').val(event.latLng.lng());
        infowindow.close();
        var geocoder = new google.maps.Geocoder();
        geocoder.geocode({
            "latLng": event.latLng
        }, function (results, status) {
            if (status == google.maps.GeocoderStatus.OK) {
                var lat = results[0].geometry.location.lat(),
                    lng = results[0].geometry.location.lng(),
                    placeName = results[0].address_components[0].long_name,
                    latlng = new google.maps.LatLng(lat, lng);

                moveMarker(placeName, latlng);
                $("#address").val(results[0].formatted_address);
            }
        });
    });

    function moveMarker(placeName, latlng) {
        marker.setIcon(image);
        marker.setPosition(latlng);
        infowindow.setContent(placeName);
        //infowindow.open(map, marker);
    }
}


let pub = '';

$(document).ready(function () {
    let ul = '';

    if (location.hostname === "earth.magicmindtechnologies.com") {
        ul = '/raja';
        pub = 'public';
    }

    var current = location.pathname;
    // console.log(current);

    $("#sidebar li a").each(function () {
        if (current == ul + "/") {
            $(".sidebar-link").eq(0).parent("li").addClass("active");
        } else {
            if ($(this).attr("href").indexOf(current) !== -1) {
                $(this).parent("li").addClass("active");
            }
        }
    });

    if (current == ul + '/banners') {
        show('banner');
    } else if (current == ul + '/branches') {
        show('branch');
    } else if (current == ul + '/categories') {
        show('category');
    } else if (current == ul + '/items') {
        show('items');
    } else if (current == ul + '/users') {
        show('users');
    } else if (current == ul + '/coupons') {
        show('coupons');
    } else if (current == ul + '/delivery') {
        show('delivery');
    } else if (current == ul + '/order-history') {
        show('orders');
    } else if (current == ul + '/tables') {
        show('tables');
    } else if (current == ul + '/gallery') {
        show('gallery');
    } else if (current == ul + '/restaurants') {
        show('restaurants');
    }


});

// RESTAURANT SECTION -----------------------------------------

$(document).on('click', '#restaurant_crate', function (e) {
    axios.get("restaurants/create")
        .then(res => {
            $('.modal-form').attr('id', res.data.formid);
            $('#modal-title').html(res.data.title);
            $('#modal-body').html(res.data.body);
            $('#btn-sbm').html(res.data.btntxt);
            $('#systemModal').modal("show");
            map(23.07854183702087, 88.17434092305791);
        })
        .catch(err => {
            console.log(err);
        });
});

$(document).on("submit", "#insertRestaurant", function (e) {
    e.preventDefault();
    $(".alert_msg").html('');
    $("#btn-sbm").html('<i class="fas fa-spinner"></i>');
    let data = new FormData(e.target);
    axios.post("restaurants", data)
        .then(res => {
            show('restaurants');
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });
            $("#btn-sbm").html('Submit');
            setTimeout(function () {
                window.location.reload();
            }, 1480);
        })
        .catch(err => {
            $("#btn-sbm").html('Submit');
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert_msg").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                errors +
                "</div>"
            );
        });

});

$(document).on("click", '.edit-restaurant', function (e) {
    axios.get('restaurants/' + $(this).val() + '/edit')
        .then(res => {

            $('.modal-form').attr('id', res.data.formid);
            $('#modal-title').html(res.data.title);
            $('#modal-body').html(res.data.body);
            $('#btn-sbm').html(res.data.btntxt);
            map(res.data.latitude, res.data.longitude);
            $('#systemModal').modal("show");
        })
        .catch(err => {
            console.log(err);

        });
});

$(document).on("submit", "#updateRestaurant", function (e) {
    e.preventDefault();
    $(".alert_msg").html('');
    $("#btn-sbm").html('<i class="fas fa-spinner"></i>');
    let data = new FormData(e.target);
    data.append("_method", "PUT");
    axios.post("restaurants/" + $('#restaurant_id').val(), data)
        .then(res => {
            $("#btn-sbm").html('Submit');
            $('#systemModal').modal("hide");
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });

            show('restaurants');

            setTimeout(function () {
                window.location.reload();
            }, 1480);
        })
        .catch(err => {
            $("#btn-sbm").html('Submit');
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert_msg").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                errors +
                "</div>"
            );
        });
});

$(document).on("click", '.del-restaurant', function (e) {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete("restaurants/" + $(this).val())
                .then(res => {
                    show('restaurants');

                    if (res.data.stat == false) {
                        Swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: res.data.msg,
                            showConfirmButton: false,
                            timer: 1500
                        });
                    } else {
                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: res.data.msg,
                            showConfirmButton: false,
                            timer: 1500
                        });

                        setTimeout(function () {
                            window.location.reload();
                        }, 1480);
                    }

                })
                .catch(err => {
                    console.log(err);

                });
        }
    })
});

$(document).on("click", '.restaurant-image', function (e) {
    axios.post("changeDefaultRestaurant", {
        restaurant_id: $('#restaurant_id').val(),
        id: $(this).val()
    })
        .then(res => {
            let data = '';
            $('.carousel-inner').html('');
            $.each(res.data.images, function (key, value) {
                if (value.is_default == '1') {
                    data += '<div class="carousel-item active"><img src="' + $('#url').val() + '/storage/images/' + value.image + '" width="304" height="200"></div>';
                } else {
                    data += '<div class="carousel-item"><img src="' + $('#url').val() + '/storage/images/' + value.image + '" width="304" height="200">';
                    data += '<div class="btn-group middle"><button type="button" class="btn btn-success restaurant-image ml-2" value="' + value.id + '">Default</button><button type="button" class="btn btn-danger del-restaurant-image" value="' + value.id + '">Delete</button></div>';
                    data += '</div>';
                }
            });

            $('.carousel-inner').html(data);
            show('restaurants');
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });
        })
        .catch(err => {
            console.log(err);

        });

});

$(document).on("click", '.del-restaurant-image', function (e) {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete("restaurant-image/" + $(this).val())
                .then(res => {
                    let data = '';
                    $('.carousel-inner').html('');
                    $.each(res.data.images, function (key, value) {
                        if (value.is_default == '1') {
                            data += '<div class="carousel-item active"><img src="' + $('#url').val() + '/storage/images/' + value.image + '" width="304" height="200"></div>';
                        } else {
                            data += '<div class="carousel-item"><img src="' + $('#url').val() + '/storage/images/' + value.image + '" width="304" height="200">';
                            data += '<div class="btn-group middle"><button type="button" class="btn btn-success restaurant-image ml-2" value="' + value.id + '">Default</button><button type="button" class="btn btn-danger del-restaurant-image" value="' + value.id + '">Delete</button></div>';
                            data += '</div>';
                        }
                    });

                    $('.carousel-inner').html(data);
                    show('restaurants');
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: res.data.msg,
                        showConfirmButton: false,
                        timer: 1500
                    });

                })
                .catch(err => {
                    console.log(err);

                });
        }
    })

});

// ADDON SECTION ----------------------------------------------
$(document).on('click', '#cate_addon_cate', function (e) {
    $(".alert-addon").html('');
    $('#addon_price').val('');
    $("#cate_addon_cate").html('<i class="fas fa-spinner"></i>');
    axios.post("addons-category", {
        category: $('#addon_category_name').val(),
        status: $("#category_status").is(":checked") ? 'active' : 'inactive',
    })
        .then(res => {

            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });

            setTimeout(function () {
                window.location.reload();
            }, 1480);

            $('#addon_category_name').val('');
            $('#addon_category').find('option').remove();
            $('#addon_category').append('<option value="">Select Category</option>');
            $.each(res.data.categories, function (key, value) {
                $('#addon_category').append('<option value="' + value.id + '">' + value.name + '</option>');
            });
            $("#cate_addon_cate").html('<i class="fa fa-plus"></i> Add New');

        })
        .catch(err => {
            $('#addon_category_name').val('');
            $("#cate_addon_cate").html('<i class="fa fa-plus"></i> Add New');
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert-addon").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                errors +
                "</div>"
            );
        });
});

$(document).on('click', '#addon_create', function (e) {
    $(".alert-addon").html('');
    $('#addon_price').val('');
    $("#addon_create").html('<i class="fas fa-spinner"></i>');
    axios.post("addons", {
        category_id: $('#addon_category').val(),
        addon: $('#addon_name').val(),
        price: $('#addon_price').val() == '' ? 0 : $('#addon_price').val(),
        status: $("#addon_status").is(":checked") ? 'active' : 'inactive',
    })
        .then(res => {

            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });
            $('#addon_name').val('');
            $('#addon').find('option').remove();
            $('#addon').append('<option value="">Select Addon</option>');
            $.each(res.data.addons, function (key, value) {
                $('#addon').append('<option value="' + value.id + '">' + value.name + '</option>');
            });
            $("#addon_create").html('<i class="fa fa-plus"></i> Add New');
            $("#addon_status").prop("checked", true);

            setTimeout(function () {
                window.location.reload();
            }, 1480);
        })
        .catch(err => {
            $('#addon_name').val('');
            $("#addon_create").html('<i class="fa fa-plus"></i> Add New');
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert-addon").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                errors +
                "</div>"
            );
        });
});

$(document).on('change', '#addon_category', function (e) {
    $(".alert-addon").html('');
    $('#addon_name').val('');
    $('#addon_price').val('');
    $('#addon_category_name').val('');

    axios.get("addons/" + $(this).val() + "/category")
        .then(res => {
            $('#addon_category_name').val(res.data.category_name);
            $('#btn-addon-first').html(res.data.first);
            $('#btn-addon-second').html(res.data.second);
            $('#btn-addon-third').html(res.data.third);
            $('#btn-addon-fourth').html(res.data.fourth);
            $('#btn-addon-fifth').html(res.data.fifth);
            if (res.data.status == 'active') {
                $("#category_status").prop("checked", true);
            } else {
                $("#category_status").prop("checked", false);
            }
            $('#addon').find('option').remove();
            $('#addon').append('<option value="0">Select Addon</option>');
            $.each(res.data.addons, function (key, value) {
                $('#addon').append('<option value="' + value.id + '">' + value.name + '</option>');
            });

            // setTimeout(function () {
            //     window.location.reload();
            // }, 1480);
        })
        .catch(err => {
            if (err.response.status == 402) {
                $('#addon_category_name').val(err.response.data.category_name);
                $('#btn-addon-first').html(err.response.data.first);
                $('#btn-addon-second').html(err.response.data.second);
                $('#btn-addon-third').html(err.response.data.third);
                $('#btn-addon-fourth').html(err.response.data.fourth);
                $('#btn-addon-fifth').html(err.response.data.fifth);
                $("#category_status").prop("checked", true);
                $('#addon').find('option').remove();

            } else if (err.response.status == 404) {
                $('#addon_category_name').val(err.response.data.category_name);
                $('#btn-addon-first').html(err.response.data.first);
                $('#btn-addon-second').html(err.response.data.second);
                $('#btn-addon-third').html(err.response.data.third);
                $('#btn-addon-fourth').html(err.response.data.fourth);
                $('#btn-addon-fifth').html(err.response.data.fifth);
                $("#category_status").prop("checked", true);
                $('#addon').find('option').remove();
            } else {
                $('#addon').find('option').remove();
                let errors = "<ul>";
                $.each(err.response.data.errors, function (key, value) {
                    errors += "<li>" + value + "</li>";
                });
                errors += "</ul>";
                $(".alert-addon").html(
                    '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                    errors +
                    "</div>"
                );
            }
        });
});

$(document).on('change', '#addon', function (e) {
    $(".alert-addon").html('');
    $('#addon_price').val('');
    $('#addon_name').val('');

    axios.get("addons/" + $(this).val() + "/addon")
        .then(res => {
            $('#addon_name').val(res.data.name);
            $('#addon_price').val(res.data.price);
            $('#btn-addon-third').html(res.data.third);
            $('#btn-addon-fourth').html(res.data.fourth);
            $('#btn-addon-fifth').html(res.data.fifth);
            if (res.data.status == 'active') {
                $("#addon_status").prop("checked", true);
            } else {
                $("#addon_status").prop("checked", false);
            }

            // setTimeout(function () {
            //     window.location.reload();
            // }, 1480);

        })
        .catch(err => {
            if (err.response.status == 402) {
                $('#addon_name').val(err.response.data.name);
                $('#btn-addon-second').html(err.response.data.second);
                $('#btn-addon-third').html(err.response.data.third);
                $('#btn-addon-fourth').html(err.response.data.fourth);
                $('#btn-addon-fifth').html(err.response.data.fifth);
                $("#addon_status").prop("checked", true);

            } else if (err.response.status == 404) {
                $('#addon_name').val(err.response.data.name);
                $('#btn-addon-third').html(err.response.data.third);
                $('#btn-addon-fourth').html(err.response.data.fourth);
                $('#btn-addon-fifth').html(err.response.data.fifth);
                $("#addon_status").prop("checked", true);
            } else {
                let errors = "<ul>";
                $.each(err.response.data.errors, function (key, value) {
                    errors += "<li>" + value + "</li>";
                });
                errors += "</ul>";
                $(".alert-addon").html(
                    '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                    errors +
                    "</div>"
                );
            }
        });
});

$(document).on('click', '#addon_price_save', function (e) {
    $(".alert-addon").html('');
    $("#addon_price_save").html('<i class="fas fa-spinner"></i>');
    axios.post("addons-price", {
        addon: $('#addon').val(),
        price: $('#addon_price').val() == '' ? 0 : $('#addon_price').val(),
    })
        .then(res => {

            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });
            $("#addon_price_save").html('<i class="fa fa-wrench"></i> Save');

            setTimeout(function () {
                window.location.reload();
            }, 1480);
        })
        .catch(err => {
            $("#addon_price_save").html('<i class="fa fa-wrench"></i> Save');
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert-addon").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                errors +
                "</div>"
            );
        });
});

$(document).on("click", "#cate_addon_save", function (e) {
    $(".alert-addon").html('');
    $("#cate_addon_save").html('<i class="fas fa-spinner"></i>');
    let data = new FormData();
    data.append("_method", "PUT");
    data.append("type", "category");
    data.append("category", $('#addon_category_name').val());
    data.append("status", $("#category_status").is(":checked") ? 'active' : 'inactive');
    axios.post("addons/" + $('#addon_category').val(), data)
        .then(res => {
            $("#cate_addon_save").html('<i class="fa fa-wrench"></i>  Update');
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });

            $('#addon_category_name').val('');
            $('#addon_name').val('');
            $('#addon_price').val('');
            $('#btn-addon-first').html(res.data.first);
            $('#btn-addon-second').html(res.data.second);
            $('#btn-addon-third').html(res.data.third);
            $('#btn-addon-fourth').html(res.data.fourth);
            $('#btn-addon-fourth').html(res.data.fourth);
            $('#btn-addon-fifth').html(res.data.fifth);
            $('#addon').find('option').remove();
            $('#addon_category').find('option').remove();
            $('#addon_category').append('<option value="0">Select Category</option>');
            $.each(res.data.categories, function (key, value) {
                $('#addon_category').append('<option value="' + value.id + '">' + value.name + '</option>');
            });

            $("#category_status").prop("checked", true);
            // setTimeout(function () {
            //     window.location.reload();
            // }, 1480);
        })
        .catch(err => {
            $("#cate_addon_save").html('<i class="fa fa-wrench"></i>  Update');
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert-addon").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                errors +
                "</div>"
            );
        });
});

$(document).on("click", "#addon_save", function (e) {
    $(".alert-addon").html('');
    $("#addon_save").html('<i class="fas fa-spinner"></i>');
    let data = new FormData();
    data.append("_method", "PUT");
    data.append("type", "addon");
    data.append("category_id", $('#addon_category').val());
    data.append("addon", $('#addon_name').val());
    data.append("status", $("#addon_status").is(":checked") ? 'active' : 'inactive');
    axios.post("addons/" + $('#addon').val(), data)
        .then(res => {
            $("#addon_save").html('<i class="fa fa-wrench"></i>  Update');
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });

            $('#addon_name').val('');
            $('#addon_price').val('');
            $('#btn-addon-third').html(res.data.third);
            $('#btn-addon-fourth').html(res.data.fourth);
            $('#btn-addon-fifth').html(res.data.fifth);
            $('#addon').find('option').remove();
            $('#addon').append('<option value="">Select Addon</option>');
            $.each(res.data.addons, function (key, value) {
                $('#addon').append('<option value="' + value.id + '">' + value.name + '</option>');
            });
            $("#addon_status").prop("checked", true);

            setTimeout(function () {
                window.location.reload();
            }, 1480);
        })
        .catch(err => {
            $("#addon_save").html('<i class="fa fa-wrench"></i>  Update');
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert-addon").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                errors +
                "</div>"
            );
        });
});

$(document).on("click", "#cate_addon_del", function (e) {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete("addons-category/" + $('#addon_category').val())
                .then(res => {
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: res.data.msg,
                        showConfirmButton: false,
                        timer: 1500
                    });

                    $('#addon_category_name').val('');
                    $('#addon_name').val('');
                    $('#addon_price').val('');
                    $("#category_status").prop("checked", true);
                    $("#addon_status").prop("checked", true);
                    $('#addon_category').find('option').remove();
                    $('#addon_category').append('<option value="0">Select Category</option>');
                    $.each(res.data.categories, function (key, value) {
                        $('#addon_category').append('<option value="' + value.id + '">' + value.name + '</option>');
                    });
                    $('#addon').find('option').remove();
                    $('#btn-addon-first').html(res.data.first);
                    $('#btn-addon-second').html(res.data.second);
                    $('#btn-addon-third').html(res.data.third);
                    $('#btn-addon-fourth').html(res.data.fourth);
                    $('#btn-addon-fifth').html(res.data.fifth);

                })
                .catch(err => {
                    console.log(err);

                });
        }
    })
});

// TIME SLOT SECTION -------------------------------------
$(document).on('click', '#save_time_slot', function (e) {
    $(".alert-addon").html('');
    // $('#addon_price').val('');
    $("#save_time_slot").html('<i class="fas fa-spinner"></i>');
    axios.post("timeslot", {
        start_time: $('#start_time').val(),
        end_time: $('#end_time').val(),
        set_interval: $("#set_interval").val()
    })
        .then(res => {
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });
            $("#save_time_slot").html(' Save Time Slot ');
            setTimeout(function () {
                window.location.reload();
            }, 1460);
        })
        .catch(err => {
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert-addon").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                errors +
                "</div>"
            );
        });
});

// SET DELIVERY TIME SLOT ---------------------------------
$(document).on('click', '#set-delivery-time', function (e) {
    $(".alert-addon").html('');
    // $('#addon_price').val('');
    $("#update_time_slot").html('<i class="fas fa-spinner"></i>');
    var id = $('#id').val();
    axios.put("timeslot/" + id, {
        start_time: $('#start_time').val(),
        end_time: $('#end_time').val(),
        set_interval: $("#set_interval").val()
    })
        .then(res => {
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });
            $("#update_time_slot").html(' Save Time Slot ');
            setTimeout(function () {
                window.location.reload();
            }, 1460);
        })
        .catch(err => {
            $("#update_time_slot").html(' Save Time Slot ');

            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert-addon").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                errors +
                "</div>"
            );
        });
});

// UPDATE DELIVERY TIME SLOT ------------------------------
$(document).on('click', '#update_time_slot', function (e) {
    $(".alert-addon").html('');
    // $('#addon_price').val('');
    $("#update_time_slot").html('<i class="fas fa-spinner"></i>');
    var id = $('#id').val();
    axios.put("timeslot/" + id, {
        start_time: $('#start_time').val(),
        end_time: $('#end_time').val(),
        set_interval: $("#set_interval").val()
    })
    .then(res => {
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: res.data.msg,
            showConfirmButton: false,
            timer: 1500
        });
        $("#update_time_slot").html(' Save Time Slot ');
        setTimeout(function () {
            window.location.reload();
        }, 1460);
    })
    .catch(err => {
        $("#update_time_slot").html(' Save Time Slot ');

        let errors = "<ul>";
        $.each(err.response.data.errors, function (key, value) {
            errors += "<li>" + value + "</li>";
        });
        errors += "</ul>";
        $(".alert-addon").html(
            '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
            errors +
            "</div>"
        );
    });
});

// SET DELIVERT TIME ---------------------------------------
$(document).on('click', '#save-delivery-time', function (e) {
    $(".alert-addon").html('');
    // $('#addon_price').val('');
    $("#save-delivery-time").html('<i class="fas fa-spinner"></i>');
    var id = $('#order').val();
    axios.post("update-delivery-time/" + id, {
        delivery_time: $("#delivery_time").val()
    })
        .then(res => {
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });
            $("#save-delivery-time").html(' Set delivery time ');
            setTimeout(function () {
                window.location.reload();
            }, 1460);
        })
        .catch(err => {
            $("#save-delivery-time").html(' Set delivery time ');

            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert-addon").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                errors +
                "</div>"
            );
        });
});

// SET TAX SECTION -------------------------------------
$(document).on('click', '#save_tax', function (e) {
    $(".alert-addon").html('');
    // $('#addon_price').val('');
    $("#save_tax").html('<i class="fas fa-spinner"></i>');
    axios.post("set-tax", {
        tax: $('#tax').val()
    })
        .then(res => {
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });
            $("#save_tax").html(' Save ');

            setTimeout(function () {
                window.location.reload();
            }, 1460);
        })
        .catch(err => {
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert-addon").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                errors +
                "</div>"
            );
        });
});

// UPDATE TAX --------------------------------------
$(document).on('click', '#update_tax', function (e) {
    $(".alert-addon").html('');
    $("#update_tax").html('<i class="fas fa-spinner"></i>');
    var id = $('#id').val();
    axios.put("set-tax/" + id, {
        tax: $('#tax').val()
    })
    .then(res => {
        Swal.fire({
            position: "top-end",
            icon: "success",
            title: res.data.msg,
            showConfirmButton: false,
            timer: 1500
        });
        $("#update_tax").html(' Save ');
        setTimeout(function () {
            window.location.reload();
        }, 1460);
    })
    .catch(err => {
        $("#update_tax").html(' Save ');

        let errors = "<ul>";
        $.each(err.response.data.errors, function (key, value) {
            errors += "<li>" + value + "</li>";
        });
        errors += "</ul>";
        $(".alert-addon").html(
            '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
            errors +
            "</div>"
        );
    });
});

$(document).on('change', '#order', function (e) {
    var id = $('#order').val();
    axios.get("get-delivery-time/" + id)
        .then(res => {
            $("#delivery_time").val(res.data.delivery_time);
        })
        .catch(err => {

        });
});

$(document).on("click", "#addon_del", function (e) {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete("addons/" + $('#addon').val())
                .then(res => {
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: res.data.msg,
                        showConfirmButton: false,
                        timer: 1500
                    });

                    $('#addon_name').val('');
                    $('#addon_price').val('');
                    $("#addon_status").prop("checked", true);
                    $('#addon').find('option').remove();
                    $('#addon').append('<option value="0">Select Addon</option>');
                    $.each(res.data.addons, function (key, value) {
                        $('#addon').append('<option value="' + value.id + '">' + value.name + '</option>');
                    });
                    $('#btn-addon-third').html(res.data.third);
                    $('#btn-addon-fourth').html(res.data.fourth);
                    $('#btn-addon-fifth').html(res.data.fifth);

                    setTimeout(function () {
                        window.location.reload();
                    }, 1480);

                })
                .catch(err => {
                    console.log(err);
                });
        }
    })
});

// DELIVERY COST SECTION ====================================
// Modal Show
$(document).on('click', '#delivery_create', function (e) {
    // alert("hi");
    axios.get("delivery/create")
        .then(res => {
            $('.modal-form').attr('id', res.data.formid);
            $('#modal-title').html(res.data.title);
            $('#modal-body').html(res.data.body);
            $('#btn-sbm').html(res.data.btntxt);
            $('#systemModal').modal("show");
        })
        .catch(err => {
            console.log(err);
        });
});

// For Delivery Cost creation
$(document).on("submit", "#insertDelivery", function (e) {
    e.preventDefault();
    $(".alert_msg").html('');
    $("#btn-sbm").html('<i class="fas fa-spinner"></i>');
    let data = new FormData(e.target);
    axios.post("delivery", data)
        .then(res => {
            show('delivery');
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });
            $("#btn-sbm").html('Submit');
            setTimeout(function () {
                window.location.reload();
            }, 1480);
        })
        .catch(err => {
            $("#btn-sbm").html('Submit');
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert_msg").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                errors +
                "</div>"
            );
        });

});
// For Delivery Cost Update
$(document).on("click", '.edit-delivery', function (e) {
    axios.get('delivery/' + $(this).val() + '/edit')
        .then(res => {
            $('.modal-form').attr('id', res.data.formid);
            $('#modal-title').html(res.data.title);
            $('#modal-body').html(res.data.body);
            $('#btn-sbm').html(res.data.btntxt);
            $('#systemModal').modal("show");
        })
        .catch(err => {
            console.log(err);
        });
});

$(document).on("submit", "#updateDelivery", function (e) {
    e.preventDefault();
    $(".alert_msg").html('');
    $("#btn-sbm").html('<i class="fas fa-spinner"></i>');
    let data = new FormData(e.target);
    data.append("_method", "PUT");
    axios.post("delivery/" + $('#delivery_cost_id').val(), data)
        .then(res => {
            $("#btn-sbm").html('Submit');
            $('#systemModal').modal("hide");
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });
            show('delivery');
            setTimeout(function () {
                window.location.reload();
            }, 1480);
        })
        .catch(err => {
            $("#btn-sbm").html('Submit');
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert_msg").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                errors +
                "</div>"
            );
        });
});

// For Delivery Cost Deletion
$(document).on("click", '.del-delivery', function (e) {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete("delivery/" + $(this).val())
                .then(res => {
                    // show('coupons');

                    if (res.data.stat == false) {
                        Swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: res.data.msg,
                            showConfirmButton: false,
                            timer: 1500
                        });
                        setTimeout(function () {
                            window.location.reload();
                        }, 1480);
                    }
                    else {
                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: res.data.msg,
                            showConfirmButton: false,
                            timer: 1500
                        });
                        show('delivery');
                    }

                })
                .catch(err => {
                    console.log(err);

                });
        }
    })
});

// COUPON SECTION --------------------------------------------

$(document).on('click', '#coupons_create', function (e) {
    axios.get("coupons/create")
        .then(res => {
            $('.modal-form').attr('id', res.data.formid);
            $('#modal-title').html(res.data.title);
            $('#modal-body').html(res.data.body);
            $('#btn-sbm').html(res.data.btntxt);
            $('#systemModal').modal("show");
        })
        .catch(err => {
            console.log(err);
        });
});

$(document).on("submit", "#insertCoupon", function (e) {
    e.preventDefault();
    $(".alert_msg").html('');
    $("#btn-sbm").html('<i class="fas fa-spinner"></i>');
    let data = new FormData(e.target);
    axios.post("coupons", data)
        .then(res => {
            // console.log(res.data)
            show('coupons');
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });
            $('#systemModal').modal("hide");
            $("#btn-sbm").html('Submit');
            setTimeout(function () {
                window.location.reload();
            }, 1480);

        })
        .catch(err => {
            $("#btn-sbm").html('Submit');
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert_msg").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                errors +
                "</div>"
            );
        });

});

$(document).on("click", '.edit-coupon', function (e) {
    axios.get('coupons/' + $(this).val() + '/edit')
        .then(res => {
            $('.modal-form').attr('id', res.data.formid);
            $('#modal-title').html(res.data.title);
            $('#modal-body').html(res.data.body);
            $('#btn-sbm').html(res.data.btntxt);
            $('#systemModal').modal("show");
        })
        .catch(err => {
            console.log(err);
        });
});

$(document).on("submit", "#updateCoupon", function (e) {
    e.preventDefault();
    $(".alert_msg").html('');
    $("#btn-sbm").html('<i class="fas fa-spinner"></i>');
    let data = new FormData(e.target);
    data.append("_method", "PUT");
    axios.post("coupons/" + $('#coupon_id').val(), data)
        .then(res => {
            $("#btn-sbm").html('Submit');
            $('#systemModal').modal("hide");
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });

            show('coupons');
            setTimeout(function () {
                window.location.reload();
            }, 1480);
        })
        .catch(err => {
            $("#btn-sbm").html('Submit');
            if (typeof err.response.data.msg != 'undefined') {
                $(".alert_msg").html(
                    '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                    err.response.data.msg +
                    "</div>"
                );
            } else {
                let errors = "<ul>";
                $.each(err.response.data.errors, function (key, value) {
                    errors += "<li>" + value + "</li>";
                });
                errors += "</ul>";
                $(".alert_msg").html(
                    '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                    errors +
                    "</div>"
                );
            }


        });
});

$(document).on("click", '.del-coupon', function (e) {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete("coupons/" + $(this).val())
                .then(res => {
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: res.data.msg,
                        showConfirmButton: true,
                        timer: 1500
                    });

                    show('coupons');

                    setTimeout(function () {
                        window.location.reload();
                    }, 1480);
                })
                .catch(err => {
                    Swal.fire({
                        position: "top-end",
                        icon: "error",
                        title: err.response.data.msg,
                        showConfirmButton: true,
                        timer: 1500
                    });
                });
        }
    })
});

$(document).on('click', '#tables_create', function (e) {
    axios.get("tables/create")
        .then(res => {
            $('.modal-form').attr('id', res.data.formid);
            $('#modal-title').html(res.data.title);
            $('#modal-body').html(res.data.body);
            $('#btn-sbm').html(res.data.btntxt);
            $('#systemModal').modal("show");
        })
        .catch(err => {
            console.log(err);
        });
});

$(document).on('click', '.edit-tables', function (e) {
    axios.get('tables/' + $(this).val() + '/edit')
        .then(res => {
            $('.modal-form').attr('id', res.data.formid);
            $('#modal-title').html(res.data.title);
            $('#modal-body').html(res.data.body);
            $('#btn-sbm').html(res.data.btntxt);
            $('#systemModal').modal("show");
        })
        .catch(err => {
            console.log(err);
        });
});

$(document).on("submit", "#insertTable", function (e) {
    e.preventDefault();
    $(".alert_msg").html('');
    $("#btn-sbm").html('<i class="fas fa-spinner"></i>');
    let data = new FormData(e.target);
    if ($('#table_id').val() != '') {
        data.append("_method", "PUT");
        data.append("action", "update");
        axios.post("tables/" + $('#table_id').val(), data)
            .then(res => {
                $('#systemModal').modal("hide");
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: res.data.msg,
                    showConfirmButton: false,
                    timer: 1500
                });

                show('tables');
                setTimeout(function () {
                    window.location.reload();
                }, 1480);
            })
            .catch(err => {
                if (typeof err.response.data.msg != 'undefined') {
                    $(".alert_msg").html(
                        '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                        err.response.data.msg +
                        "</div>"
                    );
                } else {
                    let errors = "<ul>";
                    $.each(err.response.data.errors, function (key, value) {
                        errors += "<li>" + value + "</li>";
                    });
                    errors += "</ul>";
                    $(".alert_msg").html(
                        '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                        errors +
                        "</div>"
                    );
                }
            });

    } else {
        axios.post("tables", data)
            .then(res => {
                $('#systemModal').modal("hide");
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: res.data.msg,
                    showConfirmButton: false,
                    timer: 1500
                });

                show('tables');

                setTimeout(function () {
                    window.location.reload();
                }, 1480);
            })
            .catch(err => {
                $("#btn-sbm").html('Submit');
                console.log('ERROR', err.response);
                if (typeof err.response.data.msg != 'undefined') {
                    $(".alert_msg").html(
                        '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                        err.response.data.msg +
                        "</div>"
                    );
                } else {
                    let errors = "<ul>";
                    $.each(err.response.data.errors, function (key, value) {
                        errors += "<li>" + value + "</li>";
                    });
                    errors += "</ul>";
                    $(".alert_msg").html(
                        '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                        errors +
                        "</div>"
                    );
                }
            });
    }

});

$(document).on("click", '.del-tables', function (e) {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete("tables/" + $(this).val())
                .then(res => {
                    show('tables');
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: res.data.msg,
                        showConfirmButton: false,
                        timer: 1500
                    });
                    setTimeout(function () {
                        window.location.reload();
                    }, 1480);
                })
                .catch(err => {
                    console.log(err);

                });
        }
    })
});

$(document).on('click', '#gallery_create', function (e) {
    axios.get("gallery/create")
        .then(res => {
            $('.modal-form').attr('id', res.data.formid);
            $('#modal-title').html(res.data.title);
            $('#modal-body').html(res.data.body);
            $('#btn-sbm').html(res.data.btntxt);
            $('#systemModal').modal("show");
        })
        .catch(err => {
            console.log(err);
        });
});

$(document).on("submit", "#insertGallery", function (e) {
    e.preventDefault();
    $(".alert_msg").html('');
    $("#btn-sbm").html('<i class="fas fa-spinner"></i>');
    let data = new FormData(e.target);
    if ($('#gallery_id').val() != '') {
        data.append("_method", "PUT");
        data.append("action", "update");
        axios.post("gallery/" + $('#gallery_id').val(), data)
            .then(res => {
                $('#systemModal').modal("hide");
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: res.data.msg,
                    showConfirmButton: false,
                    timer: 1500
                });

                show('gallery');
                $("#systemModal").modal('hide');
            })
            .catch(err => {
                console.log(err);

            });

    } else {
        axios.post("gallery", data)
            .then(res => {

                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: res.data.msg,
                    showConfirmButton: false,
                    timer: 1500
                });

                show('gallery');
                $("#systemModal").modal('hide');

                setTimeout(function () {
                    window.location.reload();
                }, 1480);
            })
            .catch(err => {
                $("#btn-sbm").html('Submit');
                let errors = "<ul>";
                $.each(err.response.data.errors, function (key, value) {
                    errors += "<li>" + value + "</li>";
                });
                errors += "</ul>";
                $(".alert_msg").html(
                    '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                    errors +
                    "</div>"
                );
            });
    }

});

$(document).on("click", '.edit-gallery', function (e) {
    axios.get('gallery/' + $(this).val() + '/edit')
        .then(res => {
            $('.modal-form').attr('id', res.data.formid);
            $('#modal-title').html(res.data.title);
            $('#modal-body').html(res.data.body);
            $('#btn-sbm').html(res.data.btntxt);
            $('#systemModal').modal("show");
        })
        .catch(err => {
            console.log(err);
        });
});

$(document).on("click", '.del-gallery', function (e) {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete("gallery/" + $(this).val())
                .then(res => {
                    show('gallery');
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: res.data.msg,
                        showConfirmButton: false,
                        timer: 1500
                    });
                })
                .catch(err => {
                    console.log(err);
                });
        }
    })
});

// BANNER SECTION ------------------------------------------------------

$(document).on('click', '#banner_create', function (e) {
    axios.get("banners/create")
        .then(res => {
            $('.modal-form').attr('id', res.data.formid);
            $('#modal-title').html(res.data.title);
            $('#modal-body').html(res.data.body);
            $('#btn-sbm').html(res.data.btntxt);
            $('#systemModal').modal("show");
        })
        .catch(err => {
            console.log(err);
        });
});

$(document).on("submit", "#insertBanner", function (e) {
    e.preventDefault();
    $(".alert_msg").html('');
    $("#btn-sbm").html('<i class="fas fa-spinner"></i>');
    let data = new FormData(e.target);
    if ($('#banner_id').val() != '') {
        data.append("_method", "PUT");
        data.append("action", "update");
        axios.post("banners/" + $('#banner_id').val(), data)
            .then(res => {
                $('#systemModal').modal("hide");
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: res.data.msg,
                    showConfirmButton: false,
                    timer: 1500
                });

                show('banner');
                setTimeout(function () {
                    window.location.reload();
                }, 1480);
            })
            .catch(err => {
                console.log(err);

            });

    } else {
        axios.post("banners", data)
            .then(res => {
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: res.data.msg,
                    showConfirmButton: false,
                    timer: 1500
                });

                show('banner');
                setTimeout(function () {
                    window.location.reload();
                }, 1480);
            })
            .catch(err => {
                $("#btn-sbm").html('Submit');
                let errors = "<ul>";
                $.each(err.response.data.errors, function (key, value) {
                    errors += "<li>" + value + "</li>";
                });
                errors += "</ul>";
                $(".alert_msg").html(
                    '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                    errors +
                    "</div>"
                );
            });
    }

});

$('#allBanner').on("click", '[id^="ban"]', function (e) {
    let cl = $(this).attr('id').split("-");
    // console.log(cl[2]);
    // console.log($(this).val());

    let data = new FormData();
    data.append("_method", "PUT");
    data.append("action", "update_status");
    data.append("status", $(this).val());
    // data.append("status", cl[2]);

    axios.post("banners/" + cl[2], data)
        .then(res => {
            show('banner');
            $(".ban_vi_msg").html(
                '<div class="alert alert-success  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Success!</strong> ' +
                res.data.msg +
                "</div>"
            );
        })
        .catch(err => {
            console.log(err);

        });
});

$(document).on("click", '.edit-ban', function (e) {
    axios.get('banners/' + $(this).val() + '/edit')
        .then(res => {
            $('.modal-form').attr('id', res.data.formid);
            $('#modal-title').html(res.data.title);
            $('#modal-body').html(res.data.body);
            $('#btn-sbm').html(res.data.btntxt);
            $('#systemModal').modal("show");
        })
        .catch(err => {
            console.log(err);
        });
});

$(document).on("click", '.del-ban', function (e) {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete("banners/" + $(this).val())
                .then(res => {
                    show('banner');
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: res.data.msg,
                        showConfirmButton: false,
                        timer: 1500
                    });
                    setTimeout(function () {
                        window.location.reload();
                    }, 1480);
                })
                .catch(err => {
                    console.log(err);
                });
        }
    })
});

//BRANCH SECTION ---------------------------------------------------------

$(document).on('click', '#branch_crate', function (e) {
    axios.get("branches/create")
        .then(res => {
            $('.modal-form').attr('id', res.data.formid);
            $('#modal-title').html(res.data.title);
            $('#modal-body').html(res.data.body);
            $('#btn-sbm').html(res.data.btntxt);
            $('#systemModal').modal("show");
            map(23.07854183702087, 88.17434092305791);
            $('#booking_open_time').timepicker({ 'timeFormat': 'H:i:s' });
            $('#booking_closing_time').timepicker({ 'timeFormat': 'H:i:s' });
        })
        .catch(err => {
            console.log(err);
        });
});

$(document).on("submit", "#insertBranch", function (e) {
    e.preventDefault();
    $(".alert_msg").html('');
    $("#btn-sbm").html('<i class="fas fa-spinner"></i>');
    let data = new FormData(e.target);
    axios.post("branches", data)
        .then(res => {
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });
            $("#btn-sbm").html('Submit');
            show('branch');
            setTimeout(function () {
                window.location.reload();
            }, 1480);
        })
        .catch(err => {
            $("#btn-sbm").html('Submit');
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert_msg").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                errors +
                "</div>"
            );
        });

});

$(document).on("click", '.edit-branch', function (e) {
    axios.get('branches/' + $(this).val() + '/edit')
        .then(res => {
            $('.modal-form').attr('id', res.data.formid);
            $('#modal-title').html(res.data.title);
            $('#modal-body').html(res.data.body);
            $('#btn-sbm').html(res.data.btntxt);
            map(res.data.latitude, res.data.longitude);
            $('#booking_open_time').timepicker({ 'timeFormat': 'H:i:s' });
            $('#booking_closing_time').timepicker({ 'timeFormat': 'H:i:s' });
            $('#systemModal').modal("show");
        })
        .catch(err => {
            console.log(err);
        });
});

$(document).on("submit", "#updateBranch", function (e) {
    e.preventDefault();
    $(".alert_msg").html('');
    $("#btn-sbm").html('<i class="fas fa-spinner"></i>');
    let data = new FormData(e.target);
    data.append("_method", "PUT");
    axios.post("branches/" + $('#branch_id').val(), data)
        .then(res => {
            $("#btn-sbm").html('Submit');
            $('#systemModal').modal("hide");
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });

            setTimeout(function () {
                window.location.reload();
            }, 1480);
            show('branch');
        })
        .catch(err => {
            $("#btn-sbm").html('Submit');
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert_msg").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                errors +
                "</div>"
            );
        });
});

$(document).on("click", '.branch_def', function (e) {


    axios.post('branches/default', {
        id: $(this).val(),
    })
        .then(res => {
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });

            $('button[type="button"][value="' + res.data.previous + '"]').prop('disabled', false);
            $('button[type="button"][value="' + res.data.previous + '"]').html('Set Default');
            $(this).prop('disabled', true);
            $(this).html('Active');
            setTimeout(function () {
                $('.close').click();
            }, 5000);

        })
        .catch(err => {
            console.log(err);

        });
});

$(document).on("click", '.del-branch', function (e) {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete("branches/" + $(this).val())
                .then(res => {
                    show('branch');
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: res.data.msg,
                        showConfirmButton: false,
                        timer: 1500
                    });
                    setTimeout(function () {
                        window.location.reload();
                    }, 1480);
                })
                .catch(err => {
                    console.log(err);

                });
        }
    })
});

//CATEGORIES SECTION --------------------------------------------------------

$(document).on('click', '#category_crate', function (e) {
    axios.get("categories/create")
        .then(res => {
            $('.modal-form').attr('id', res.data.formid);
            $('#modal-title').html(res.data.title);
            $('#modal-body').html(res.data.body);
            $('#btn-sbm').html(res.data.btntxt);
            $('#systemModal').modal("show");
        })
        .catch(err => {
            console.log(err);
        });
});

$(document).on("submit", "#insertCategory", function (e) {
    e.preventDefault();
    $(".alert_msg").html('');
    $("#btn-sbm").html('<i class="fas fa-spinner"></i>');
    let data = new FormData(e.target);
    axios.post("categories", data)
        .then(res => {
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });
            $('#systemModal').modal("hide");
            $("#btn-sbm").html('Submit');
            show('category');
            setTimeout(function () {
                window.location.reload();
            }, 1480);
        })
        .catch(err => {
            $("#btn-sbm").html('Submit');
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert_msg").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                errors +
                "</div>"
            );
        });
});

$(document).on("click", '.edit-category', function (e) {
    axios.get('categories/' + $(this).val() + '/edit')
        .then(res => {
            $('.modal-form').attr('id', res.data.formid);
            $('#modal-title').html(res.data.title);
            $('#modal-body').html(res.data.body);
            $('#btn-sbm').html(res.data.btntxt);
            $('#systemModal').modal("show");
        })
        .catch(err => {
            console.log(err);
        });
});

$(document).on("submit", "#updateCategory", function (e) {
    e.preventDefault();
    $(".alert_msg").html('');
    $("#btn-sbm").html('<i class="fas fa-spinner"></i>');
    let data = new FormData(e.target);
    data.append("_method", "PUT");
    axios.post("categories/" + $('#category_id').val(), data)
        .then(res => {
            $("#btn-sbm").html('Submit');
            $('#systemModal').modal("hide");
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });

            show('category');

            setTimeout(function () {
                window.location.reload();
            }, 1480);
        })
        .catch(err => {
            $("#btn-sbm").html('Submit');
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert_msg").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                errors +
                "</div>"
            );
        });
});

$(document).on("click", '.del-category', function (e) {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete("categories/" + $(this).val())
                .then(res => {
                    show('category');
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: res.data.msg,
                        showConfirmButton: false,
                        timer: 1500
                    });
                    setTimeout(function () {
                        window.location.reload();
                    }, 1480);
                })
                .catch(err => {
                    console.log(err);

                });
        }
    })

});


// ITEMS SECTION ------------------------------------------------------------
$(document).on('click', '#item_crate', function (e) {
    axios.get("items/create")
        .then(res => {
            $('.modal-form').attr('id', res.data.formid);
            $('#modal-title').html(res.data.title);
            $('#modal-body').html(res.data.body);
            $('#btn-sbm').html(res.data.btntxt);
            $('#addon_category').fastselect();
            $('#systemModal').modal("show");
        })
        .catch(err => {
            console.log(err);
        });
});

$(document).on("submit", "#insertItem", function (e) {
    e.preventDefault();
    $(".alert_msg").html('');
    $("#btn-sbm").html('<i class="fas fa-spinner"></i>');
    let data = new FormData(e.target);
    axios.post("items", data)
        .then(res => {
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });

            $('#systemModal').modal("hide");
            $("#btn-sbm").html('Submit');
            show('items');

            setTimeout(function () {
                window.location.reload();
            }, 1480);
        })
        .catch(err => {
            $("#btn-sbm").html('Submit');
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert_msg").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                errors +
                "</div>"
            );
        });

});

$(document).on("click", '.edit-item', function (e) {
    axios.get('items/' + $(this).val() + '/edit')
        .then(res => {
            $('.modal-form').attr('id', res.data.formid);
            $('#modal-title').html(res.data.title);
            $('#modal-body').html(res.data.body);
            $('#btn-sbm').html(res.data.btntxt);
            $('#addon_category').fastselect();
            $('#systemModal').modal("show");
        })
        .catch(err => {
            console.log(err);
        });
});

$(document).on("submit", "#updateItem", function (e) {
    e.preventDefault();
    $(".alert_msg").html('');
    $("#btn-sbm").html('<i class="fas fa-spinner"></i>');
    let data = new FormData(e.target);
    data.append("_method", "PUT");
    axios.post("items/" + $('#item_id').val(), data)
        .then(res => {
            $("#btn-sbm").html('Submit');
            $('#systemModal').modal("hide");
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });
            show('items');
            setTimeout(function () {
                window.location.reload();
            }, 1480);
        })
        .catch(err => {
            $("#btn-sbm").html('Submit');
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert_msg").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                errors +
                "</div>"
            );
        });
});

$(document).on("click", '.del-item', function (e) {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete("items/" + $(this).val())
                .then(res => {
                    show('items');
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: res.data.msg,
                        showConfirmButton: false,
                        timer: 1500
                    });
                    setTimeout(function () {
                        window.location.reload();
                    }, 1480);
                })
                .catch(err => {
                    console.log(err);
                });
        }
    })

});

$(document).on("click", '.item-image', function (e) {
    axios.post("changeDefault", {
        item_id: $('#item_id').val(),
        id: $(this).val()
    })
        .then(res => {
            let data = '';
            $('.carousel-inner').html('');
            $.each(res.data.images, function (key, value) {
                if (value.is_default == '1') {
                    data += '<div class="carousel-item active"><img src="' + $('#url').val() + '/storage/images/' + value.image + '" width="304" height="200"></div>';
                } else {
                    data += '<div class="carousel-item"><img src="' + $('#url').val() + '/storage/images/' + value.image + '" width="304" height="200">';
                    data += '<div class="btn-group middle"><button type="button" class="btn btn-success item-image ml-2" value="' + value.id + '">Default</button><button type="button" class="btn btn-danger del-item-image" value="' + value.id + '">Delete</button></div>';
                    data += '</div>';
                }
            });

            $('.carousel-inner').html(data);
            show('items');
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });
            setTimeout(function () {
                window.location.reload();
            }, 1490);
        })
        .catch(err => {
            console.log(err);
        });

});

$(document).on("click", '.del-item-image', function (e) {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete("item-image/" + $(this).val())
                .then(res => {
                    let data = '';
                    $('.carousel-inner').html('');
                    $.each(res.data.images, function (key, value) {
                        if (value.is_default == '1') {
                            data += '<div class="carousel-item active"><img src="' + $('#url').val() + '/storage/images/' + value.image + '" width="304" height="200"></div>';
                        } else {
                            data += '<div class="carousel-item"><img src="' + $('#url').val() + '/storage/images/' + value.image + '" width="304" height="200">';
                            data += '<div class="btn-group middle"><button type="button" class="btn btn-success item-image ml-2" value="' + value.id + '">Default</button><button type="button" class="btn btn-danger del-item-image" value="' + value.id + '">Delete</button></div>';
                            data += '</div>';
                        }
                    });

                    $('.carousel-inner').html(data);
                    show('items');
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: res.data.msg,
                        showConfirmButton: false,
                        timer: 1500
                    });
                })
                .catch(err => {
                    console.log(err);
                });
        }
    })

});

// USERS SECTION --------------------------------------------------------------
console.log($('#url').val());
$(document).on('click', '#user_crate', function (e) {

    (async () => {

        const { value: user } = await Swal.fire({
            title: 'Select User Type',
            input: 'radio',
            inputOptions: {
                // 'customer': 'Customer',
                // 'waiter': 'Waiter',
                // 'kitchen': 'Chef',
                'delivery_boy': 'Delivery Boy',
            },
            inputValidator: (value) => {
                if (!value) {
                    return 'You need to choose something!'
                }
            }
        })

        if (user) {
            axios.get("users/create/" + `${user}`)
                .then(res => {
                    $('.modal-form').attr('id', res.data.formid);
                    $('#modal-title').html(res.data.title);
                    $('#modal-body').html(res.data.body);
                    $('#btn-sbm').html(res.data.btntxt);
                    $.getJSON($('#url').val() + '/' + pub + "/country_prefix.json", function (data) {
                        $.each(data, function (key, val) {
                            if (val.code == 'IN') {
                                $('#country_prefix').append('<option value="' + val.dial_code + '" selected>' + val.code + '(' + val.dial_code + ')' + '</option>');
                            } else {
                                $('#country_prefix').append('<option value="' + val.dial_code + '">' + val.code + '(' + val.dial_code + ')' + '</option>');
                            }
                            // console.log(key, val);
                        });
                    });

                    // $.getJSON($('#url').val() + "/country.json", function (data) {
                    //     $.each(data, function (key, val) {
                    //         if (val.code == 'IN') {
                    //             $('#country').append('<option value="' + val.name + '" selected>' + val.name + '</option>');
                    //         } else {
                    //             $('#country').append('<option value="' + val.name + '">' + val.name + '</option>');
                    //         }
                    //         // console.log(key, val);
                    //     });
                    // });

                    $('#systemModal').modal("show");
                })
                .catch(err => {
                    console.log(err);
                });
        }

    })()
});

$(document).on("submit", "#insertUser", function (e) {
    e.preventDefault();
    $(".alert_msg").html('');
    $("#btn-sbm").html('<i class="fas fa-spinner"></i>');
    let data = new FormData(e.target);
    axios.post("users", data)
        .then(res => {
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });
            console.log("res", res);
            setTimeout(function () {
                window.location.reload();
            }, 1470);
            $("#btn-sbm").html('Submit');
            show('users');
        })
        .catch(err => {
            $("#btn-sbm").html('Submit');
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert_msg").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                errors +
                "</div>"
            );
        });

});

$(document).on("click", '.edit-user', function (e) {
    axios.get('users/' + $(this).val() + '/edit')
        .then(res => {
            $('.modal-form').attr('id', res.data.formid);
            $('#modal-title').html(res.data.title);
            $('#modal-body').html(res.data.body);
            $('#btn-sbm').html(res.data.btntxt);
            $.getJSON($('#url').val() + '/' + pub + "/country_prefix.json", function (data) {
                $.each(data, function (key, val) {
                    if (res.data.country_prefix != '') {
                        if (val.dial_code == res.data.country_prefix) {
                            $('#country_prefix').append('<option value="' + val.dial_code + '" selected>' + val.code + '(' + val.dial_code + ')' + '</option>');
                        } else {
                            $('#country_prefix').append('<option value="' + val.dial_code + '">' + val.code + '(' + val.dial_code + ')' + '</option>');
                        }
                    } else {
                        if (val.code == 'IN') {
                            $('#country_prefix').append('<option value="' + val.dial_code + '" selected>' + val.code + '(' + val.dial_code + ')' + '</option>');
                        } else {
                            $('#country_prefix').append('<option value="' + val.dial_code + '">' + val.code + '(' + val.dial_code + ')' + '</option>');
                        }
                    }

                    // console.log(key, val);
                });
            });

            // $.getJSON($('#url').val() + "/country.json", function (data) {
            //     $.each(data, function (key, val) {
            //         if (res.data.country != '') {
            //             if (res.data.country == val.name) {
            //                 $('#country').append('<option value="' + val.name + '" selected>' + val.name + '</option>');
            //             } else {
            //                 $('#country').append('<option value="' + val.name + '">' + val.name + '</option>');
            //             }
            //         } else {
            //             if (val.name == 'IN') {
            //                 $('#country').append('<option value="' + val.name + '" selected>' + val.name + '</option>');
            //             } else {
            //                 $('#country').append('<option value="' + val.name + '">' + val.name + '</option>');
            //             }
            //         }

            //         // console.log(key, val);
            //     });
            // });
            $('#systemModal').modal("show");
        })
        .catch(err => {
            console.log(err);

        });
});




$(document).on("submit", "#updateUser", function (e) {
    e.preventDefault();
    $(".alert_msg").html('');
    $("#btn-sbm").html('<i class="fas fa-spinner"></i>');
    let data = new FormData(e.target);
    data.append("_method", "PUT");
    axios.post("users/" + $('#user_id').val(), data)
        .then(res => {
            $("#btn-sbm").html('Submit');
            $('#systemModal').modal("hide");
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });
            show('users');
            setTimeout(function () {
                window.location.reload();
            }, 1470);
        })
        .catch(err => {
            $("#btn-sbm").html('Submit');
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert_msg").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                errors +
                "</div>"
            );
        });
});

$(document).on("click", '.del-user', function (e) {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete("users/" + $(this).val())
                .then(res => {
                    console.log('res', res);
                    show('users');
                    Swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: res.data.msg,
                        showConfirmButton: false,
                        timer: 1500
                    });
                    setTimeout(function () {
                        window.location.reload();
                    }, 1470);
                })
                .catch(err => {
                    Swal.fire(err.response.data.msg, "", "error");
                    console.log(err);
                });
        }
    })

});
// ********ORDER HISTORY PAGE -----------------------------------------------------******
$(document).on("click", '.cancel-order', function (e) {
    // console.log($(this).val());
    // return false;
    Swal.fire({
        title: 'Are you sure?',
        text: "This order will be canceld!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, cancel it!'

    }).then((result) => {
        if (result.isConfirmed) {
            axios.post("cancel-order/" + $(this).val())
                .then(res => {
                    console.log("cancel res", res)
                    show('orders');
                    if (res.data.success) {
                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: res.data.msg,
                            showConfirmButton: false,
                            timer: 1500
                        });
                    } else {
                        Swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: res.data.msg,
                            showConfirmButton: false,
                            timer: 1500
                        });
                    }
                })
                .catch(err => {
                    console.log(err);

                });
        }
    })

});

$(document).on("click", '.refund-order', function (e) {
    // console.log($(this).val());
    // return false;
    Swal.fire({
        title: 'Are you sure?',
        text: "This order will be refunded!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Refund it!'

    }).then((result) => {
        if (result.isConfirmed) {
            axios.post("refund-order/" + $(this).val())
                .then(res => {
                    show('orders');
                    if (res.data.success) {
                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: res.data.msg,
                            showConfirmButton: false,
                            timer: 1500
                        });
                    } else {
                        Swal.fire({
                            position: "top-end",
                            icon: "error",
                            title: res.data.msg,
                            showConfirmButton: false,
                            timer: 1500
                        });
                    }
                })
                .catch(err => {
                    console.log(err);

                });
        }
    })

});

$(document).on('click', '.show-order', function (e) {
    axios.get("order-show/" + $(this).attr('id'))
        .then(res => {
            console.log("result", res);
            $('.modal-form').attr('id', res.data.formid);
            $('#modal-title').html(res.data.title);
            $('#modal-body').html(res.data.body);
            $('#btn-sbm').html(res.data.btntxt);

            $('#btn-print').html(res.data.print_btntxt);
            $('#btn-print').attr("href", res.data.url);
            // console.log(res.data.orderstat);
            if (res.data.orderstat == "Completed") {
                $('#btn-sbm').addClass('d-none');
                $('#btn-print').removeClass('d-none');
            } else {
                if (res.data.orderstat == "Ready" && res.data.delivery_type == "delivery") {
                    $('#btn-sbm').addClass('d-none');
                    $('#btn-print').removeClass('d-none');
                } else {
                    $('#btn-sbm').removeClass('d-none');
                    $('#btn-print').removeClass('d-none');
                }

            }
            // $('#addon_category').fastselect();
            $('#systemModal').modal("show");
        })
        .catch(err => {
            console.log(err);
        });
});

$(document).on("submit", "#orderchange_status", function (e) {
    e.preventDefault();
    $(".alert_msg").html('');
    $("#btn-sbm").html('<i class="fas fa-spinner"></i>');
    let data = new FormData(e.target);
    axios.post("change-order-status", data)
        .then(res => {
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            });
            window.location.href = 'order-history'
        })
        .catch(err => {
            $("#btn-sbm").html('Submit');
            let errors = "<ul>";
            $.each(err.response.data.errors, function (key, value) {
                errors += "<li>" + value + "</li>";
            });
            errors += "</ul>";
            $(".alert_msg").html(
                '<div class="alert alert-danger  alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button><div class="alert-message"><strong>Warning!</strong> ' +
                errors +
                "</div>"
            );
        });

});

// map view search
$(document).on('input', '#search-input', function (e) {
    var add = (document.getElementById('search-input'));
    var autocomplete = new google.maps.places.Autocomplete(add);
    autocomplete.setTypes(['geocode']);
    google.maps.event.addListener(autocomplete, 'place_changed', function () {
        var place = autocomplete.getPlace();
        if (!place.geometry) {
            return;
        }

        var address = '';
        if (place.address_components) {
            address = [
                (place.address_components[0] && place.address_components[0].short_name || ''),
                (place.address_components[1] && place.address_components[1].short_name || ''),
                (place.address_components[2] && place.address_components[2].short_name || '')
            ].join(' ');
        }
    });
    var geocoder = new google.maps.Geocoder();
    var address = document.getElementById("search-input").value;
    if (address.length < 3) {
        return false;
    }
    geocoder.geocode({ 'address': address }, function (results, status) {
        // console.log("Geocode");
        // console.log(status);
        // console.log(google.maps.GeocoderStatus.OK);

        if (status == google.maps.GeocoderStatus.OK) {
            // console.log("Geocode success!");
            var lat = results[0].geometry.location.lat();
            var long = results[0].geometry.location.lng();
            console.log(lat, long)
            map(lat, long);
        }

        else {
            console.log("Geocode was not successful for the following reason: " + status);
        }
    });
});